/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
    isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
    title: "Mark Friedländer",
    description:
        "Softwareentwicklung bedeutet für mich Leidenschaft. Erst wenn die Softwarelösung die Erwartungen und Anforderungen meiner Kunden übertrifft, ist meine Arbeit getan.",
    og: {
        title: "Mark Friedländer",
        type: "website",
        url: "https://friedlaender.berlin/",
    },
};

//Home Page
const greeting = {
    title: "Mark Friedländer",
    logo_name: "Mark Friedlander",
    subTitle:
        "Softwareentwickler aus Leidenschaft, dessen Fokus auf Kommunikation, Kundenzufriedenheit und dem Ziel, seinen Beitrag zur Digitalisierung zu leisten, liegt.",
    resumeLink:
        "https://drive.google.com/file/d/1bXRknv_h-XI_3CQ3SGPteGODtvEb7YvI/view?usp=sharing",
    githubProfile: "https://github.com/markfried96",
};

const socialMediaLinks = [
    /* Your Social Media Link */
    // github: "https://github.com/ashutosh1919",
    // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
    // gmail: "ashutoshhathidara98@gmail.com",
    // gitlab: "https://gitlab.com/ashutoshhathidara98",
    // facebook: "https://www.facebook.com/laymanbrother.19/",
    // twitter: "https://twitter.com/ashutosh_1919",
    // instagram: "https://www.instagram.com/layman_brother/"

    {
        name: "Github",
        link: "https://github.com/markfried96",
        fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
        backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
    },
    {
        name: "LinkedIn",
        link: "https://www.linkedin.com/in/mark-friedländer-09876810a/",
        fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
        backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
    },
    {
        name: "XING",
        link: "https://www.xing.com/profile/Mark_Friedlaender/web_profiles?expandNeffi=true",
        fontAwesomeIcon: "fa-xing", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
        backgroundColor: "#006567", // Reference https://simpleicons.org/?q=linkedin
    },
    // {
    //     name: "YouTube",
    //     link: "https://youtube.com/c/DevSense19",
    //     fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    //     backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
    // },
    {
        name: "Gmail",
        link: "mailto:mark@friedlaender.berlin",
        fontAwesomeIcon: "fa-whatsapp", // Reference https://fontawesome.com/icons/google?style=brands
        backgroundColor: "#25D366", // Reference https://simpleicons.org/?q=gmail
    },
    {
        name: "X-Twitter",
        link: "https://x.com/IT_markfried",
        fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
        backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
    },
    {
        name: "Instagram",
        link: "https://www.instagram.com/markfried/",
        fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
        backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
    },
];

const skills = {
    data: [
        {
            title: "Full-Stack-Entwicklung",
            fileName: "FullStackImg",
            skills: [
                "⚡ Individuell gestaltete (Responsive) Webanwendungen mit React",
                "⚡ Mobile Applikationen (Apps) oder PWAs mit React, React-Native oder Flutter",
                "⚡ Backend-Anwendungen inklusive REST-Schnittstelle mit Node.JS und Express.JS",
                "⚡ Relationale SQL-Datenbanken (MySQL, PostgreSQL, MSSQL, SQLite)",
            ],
            softwareSkills: [
                {
                    skillName: "HTML5",
                    fontAwesomeClassname: "simple-icons:html5",
                    style: {
                        color: "#E34F26",
                    },
                },
                {
                    skillName: "CSS3",
                    fontAwesomeClassname: "fa-css3",
                    style: {
                        color: "#1572B6",
                    },
                },
                {
                    skillName: "Sass",
                    fontAwesomeClassname: "simple-icons:sass",
                    style: {
                        color: "#CC6699",
                    },
                },
                {
                    skillName: "JavaScript",
                    fontAwesomeClassname: "simple-icons:javascript",
                    style: {
                        backgroundColor: "#000000",
                        color: "#F7DF1E",
                    },
                },
                {
                    skillName: "ReactJS",
                    fontAwesomeClassname: "simple-icons:react",
                    style: {
                        color: "#61DAFB",
                    },
                },
                {
                    skillName: "NodeJS",
                    fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
                    style: {
                        color: "#339933",
                    },
                },
                {
                    skillName: "NPM",
                    fontAwesomeClassname: "simple-icons:npm",
                    style: {
                        color: "#CB3837",
                    },
                },
                {
                    skillName: "Yarn",
                    fontAwesomeClassname: "simple-icons:yarn",
                    style: {
                        color: "#2C8EBB",
                    },
                },
                {
                    skillName: "GitHub",
                    fontAwesomeClassname: "mdi:github",
                    style: {
                        color: "#181717",
                    },
                },
                {
                    skillName: "Flutter",
                    fontAwesomeClassname: "simple-icons:flutter",
                    style: {
                        color: "#02569B",
                    },
                },
            ],
        },
        {
            title: "Full-Stack-Consulting",
            fileName: "consulting",
            filePath: "consulting.svg",
            skills: [
                "⚡ Beratung & Unterstützung beim Entwurf & Design einer Full-Stack-Webanwendung",
                "⚡ Beratung bei der Digitalisierung & Automatisierung von manuellen und/oder analogen Prozessen",
                "⚡ Beratung bei der Auswahl eines geeigneten Technologiestacks",
                "⚡ Beratung & Unterstützung bei der Auswahl und dem Design einer geeigneten Datenbanklösung",
            ],
            softwareSkills: []
        },
        {
            title: "Machine Learning & Künstliche Intelligenz & Data-Science",
            fileName: "DataScienceImg",
            skills: [
                "⚡ Beratung & Unterstützung bei Fragen und Entscheidungen im Bereich ML/KI & Data-Science",
                "⚡ Konzeption & Entwicklung von Modellen für die produktive Anwendung in unterschiedlichen Anwendungsbereichen von ML/AI",
                "⚡ Fundierte Kenntnisse in unterschiedlichsten ML-Algorithmen, ML-Ansätzen und Predicitive Maintenance, "
            ],
            softwareSkills: [
                {
                    skillName: "Tensorflow",
                    fontAwesomeClassname: "logos-tensorflow",
                    style: {
                        backgroundColor: "transparent",
                    },
                },
                {
                    skillName: "Keras",
                    fontAwesomeClassname: "simple-icons:keras",
                    style: {
                        backgroundColor: "white",
                        color: "#D00000",
                    },
                },
                {
                    skillName: "PyTorch",
                    fontAwesomeClassname: "logos-pytorch",
                    style: {
                        backgroundColor: "transparent",
                    },
                },
                {
                    skillName: "Python",
                    fontAwesomeClassname: "ion-logo-python",
                    style: {
                        backgroundColor: "transparent",
                        color: "#3776AB",
                    },
                },
                {
                    skillName: "Deeplearning",
                    imageSrc: "deeplearning_ai_logo.png",
                },
            ],
        },
        {
            title: "Cloud Infrastruktur und Architektur",
            fileName: "CloudInfraImg",
            skills: [
                "⚡ Erfahrungen in der Verwendung verschiedener Cloud-Plattformen",
                "⚡ Beratung, Unterstützung und Einrichtung einer Cloud-Infratstruktur für Ihr Unternehmen/Projekte"
            ],
            softwareSkills: [
                {
                    skillName: "GCP",
                    fontAwesomeClassname: "simple-icons:googlecloud",
                    style: {
                        color: "#4285F4",
                    },
                },
                {
                    skillName: "AWS",
                    fontAwesomeClassname: "simple-icons:amazonaws",
                    style: {
                        color: "#FF9900",
                    },
                },
                {
                    skillName: "Azure",
                    fontAwesomeClassname: "simple-icons:microsoftazure",
                    style: {
                        color: "#0089D6",
                    },
                },
                {
                    skillName: "Firebase",
                    fontAwesomeClassname: "simple-icons:firebase",
                    style: {
                        color: "#FFCA28",
                    },
                },
                {
                    skillName: "PostgreSQL",
                    fontAwesomeClassname: "simple-icons:postgresql",
                    style: {
                        color: "#336791",
                    },
                },
                {
                    skillName: "MongoDB",
                    fontAwesomeClassname: "simple-icons:mongodb",
                    style: {
                        color: "#47A248",
                    },
                },
                {
                    skillName: "Docker",
                    fontAwesomeClassname: "simple-icons:docker",
                    style: {
                        color: "#1488C6",
                    },
                },
                {
                    skillName: "Kubernetes",
                    fontAwesomeClassname: "simple-icons:kubernetes",
                    style: {
                        color: "#326CE5",
                    },
                },
            ],
        },
        // {
        //     title: "UI/UX Design",
        //     fileName: "DesignImg",
        //     skills: [
        //         "⚡ Designing highly attractive user interface for mobile and web applications",
        //         "⚡ Customizing logo designs and building logos from scratch",
        //         "⚡ Creating the flow of application functionalities to optimize user experience",
        //     ],
        //     softwareSkills: [
        //         {
        //             skillName: "Adobe XD",
        //             fontAwesomeClassname: "simple-icons:adobexd",
        //             style: {
        //                 color: "#FF2BC2",
        //             },
        //         },
        //         {
        //             skillName: "Figma",
        //             fontAwesomeClassname: "simple-icons:figma",
        //             style: {
        //                 color: "#F24E1E",
        //             },
        //         },
        //         {
        //             skillName: "Adobe Illustrator",
        //             fontAwesomeClassname: "simple-icons:adobeillustrator",
        //             style: {
        //                 color: "#FF7C00",
        //             },
        //         },
        //         {
        //             skillName: "Inkscape",
        //             fontAwesomeClassname: "simple-icons:inkscape",
        //             style: {
        //                 color: "#000000",
        //             },
        //         },
        //     ],
        // },
    ],
};

// Education Page
const competitiveSites = {
    competitiveSites: [
        // {
        //     siteName: "LeetCode",
        //     iconifyClassname: "simple-icons:leetcode",
        //     style: {
        //         color: "#F79F1B",
        //     },
        //     profileLink: "https://leetcode.com/layman_brother/",
        // },
        // {
        //     siteName: "HackerRank",
        //     iconifyClassname: "simple-icons:hackerrank",
        //     style: {
        //         color: "#2EC866",
        //     },
        //     profileLink: "https://www.hackerrank.com/layman_brother",
        // },
        // {
        //     siteName: "Codechef",
        //     iconifyClassname: "simple-icons:codechef",
        //     style: {
        //         color: "#5B4638",
        //     },
        //     profileLink: "https://www.codechef.com/users/ashutosh_1919",
        // },
        // {
        //     siteName: "Codeforces",
        //     iconifyClassname: "simple-icons:codeforces",
        //     style: {
        //         color: "#1F8ACB",
        //     },
        //     profileLink: "http://codeforces.com/profile/layman_brother",
        // },
        // {
        //     siteName: "Hackerearth",
        //     iconifyClassname: "simple-icons:hackerearth",
        //     style: {
        //         color: "#323754",
        //     },
        //     profileLink: "https://www.hackerearth.com/@ashutosh391",
        // },
        // {
        //     siteName: "Kaggle",
        //     iconifyClassname: "simple-icons:kaggle",
        //     style: {
        //         color: "#20BEFF",
        //     },
        //     profileLink: "https://www.kaggle.com/laymanbrother",
        // },
    ],
};

const degrees = {
    degrees: [
        {
            title: "Technische Universität Berlin (TU Berlin)",
            subtitle: "B.Sc. in Medieninformatik",
            logo_path: "TU-Berlin-Logo.svg",
            alt_name: "TU Berlin Logo",
            duration: "2014 - 2019",
            descriptions: [
                "⚡ Bachelorarbeit im Bereich Machine Learning & Deep Learning: - Vergleichende Evaluation von Reinforcement Learning Methoden auf Basis des trainierbaren Zustandraumes -",
            ],
            website_link: "https://www.tu.berlin/",
        },
        {
            title: "Hochschule für Technik, Wirtschaft und Kultur Leipzig (HTWK Leipzig)",
            subtitle: "M.Sc. in Informations- und Kommunikationstechnik",
            logo_path: "HTWK-Logo.svg",
            alt_name: "HTWK Leipzig",
            duration: "2021 - 2023",
            descriptions: [
                "⚡ Vertiefung meiner Kentnisse in den Bereichen Softwarearchitektur, Big Data, IT-Sicherheit und Machine Learning / Künstliche Intelligenz",
                "⚡ Praxisbezogene Masterarbeit im Bereich Machine Learning / Predictive Maintenance: - Analyse und Vergleich von Machine-Learning-Modellen auf Datenströmen für die Predictive Maintenance im IIOT -",
            ],
            website_link: "https://www.indiana.edu/",
        },
    ],
};

const certifications = {
    certifications: [
        {
            title: "Machine Learning",
            subtitle: "- Andrew Ng",
            logo_path: "stanford_logo.png",
            certificate_link:
                "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
            alt_name: "Stanford University",
            color_code: "#8C151599",
        },
        {
            title: "Deep Learning",
            subtitle: "- Andrew Ng",
            logo_path: "deeplearning_ai_logo.png",
            certificate_link:
                "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
            alt_name: "deeplearning.ai",
            color_code: "#00000099",
        },
        {
            title: "ML on GCP",
            subtitle: "- GCP Training",
            logo_path: "google_logo.png",
            certificate_link:
                "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
            alt_name: "Google",
            color_code: "#0C9D5899",
        },
        {
            title: "Data Science",
            subtitle: "- Alex Aklson",
            logo_path: "ibm_logo.png",
            certificate_link:
                "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
            alt_name: "IBM",
            color_code: "#1F70C199",
        },
        {
            title: "Big Data",
            subtitle: "- Kim Akers",
            logo_path: "microsoft_logo.png",
            certificate_link:
                "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
            alt_name: "Microsoft",
            color_code: "#D83B0199",
        },
        {
            title: "Advanced Data Science",
            subtitle: "- Romeo Kienzler",
            logo_path: "ibm_logo.png",
            certificate_link:
                "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
            alt_name: "IBM",
            color_code: "#1F70C199",
        },
        {
            title: "Advanced ML on GCP",
            subtitle: "- GCP Training",
            logo_path: "google_logo.png",
            certificate_link:
                "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
            alt_name: "Google",
            color_code: "#0C9D5899",
        },
        {
            title: "DL on Tensorflow",
            subtitle: "- Laurence Moroney",
            logo_path: "deeplearning_ai_logo.png",
            certificate_link:
                "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
            alt_name: "deeplearning.ai",
            color_code: "#00000099",
        },
        {
            title: "Fullstack Development",
            subtitle: "- Jogesh Muppala",
            logo_path: "coursera_logo.png",
            certificate_link:
                "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
            alt_name: "Coursera",
            color_code: "#2A73CC",
        },
        {
            title: "Kuberenetes on GCP",
            subtitle: "- Qwiklabs",
            logo_path: "gcp_logo.png",
            certificate_link:
                "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
            alt_name: "GCP",
            color_code: "#4285F499",
        },
        {
            title: "Cryptography",
            subtitle: "- Saurabh Mukhopadhyay",
            logo_path: "nptel_logo.png",
            certificate_link:
                "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
            alt_name: "NPTEL",
            color_code: "#FFBB0099",
        },
        {
            title: "Cloud Architecture",
            subtitle: "- Qwiklabs",
            logo_path: "gcp_logo.png",
            certificate_link:
                "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
            alt_name: "GCP",
            color_code: "#4285F499",
        },
    ],
};

// Experience Page
const experience = {
    title: "Experience",
    subtitle: "Work, Internship and Volunteership",
    description:
        "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
    header_image_path: "experience.svg",
    sections: [
        {
            title: "Work",
            work: true,
            experiences: [
                {
                    title: "Machine Learning Engineer",
                    company: "TikTok Inc.",
                    company_url: "https://www.tiktok.com/en/",
                    logo_path: "tiktok_logo.png",
                    duration: "June 2023 - Present",
                    location: "San Jose, CA, USA",
                    description:
                        "Improving ads ranking models on the core TikTok product. Experience working on modeling two-tower architectures like DeepFM, Wide & deep learning, etc. Working on Large Language Models (LLM) pretraining and Large Multi-modal Model (LMM) finetuning strategies.",
                    color: "#000000",
                },
                {
                    title: "Associate AI Engineer",
                    company: "Legato Health Technology",
                    company_url: "https://legatohealthtech.com/",
                    logo_path: "legato_logo.png",
                    duration: "June 2020 - Aug 2021",
                    location: "Hyderabad, Telangana",
                    description:
                        "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
                    color: "#0879bf",
                },
                {
                    title: "Android and ML Developer",
                    company: "Muffito Incorporation",
                    company_url: "https://www.linkedin.com/company/muffito-inc/about/",
                    logo_path: "muffito_logo.png",
                    duration: "May 2018 - Oct 2018",
                    location: "Pune, Maharashtra",
                    description:
                        "I have created complete Android Application for locating Pub, Bar and beverage shops around you. I have also worked on implementation of algorithms for Face Detection, Text extraction from Image. I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
                    color: "#9b1578",
                },
                {
                    title: "Android Developer",
                    company: "FreeCopy Pvt. Ltd.",
                    company_url: "https://www.linkedin.com/company/freecopy/about/",
                    logo_path: "freecopy_logo.png",
                    duration: "Nov 2017 - Dec 2017",
                    location: "Ahmedabad, Gujarat",
                    description:
                        "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
                    color: "#fc1f20",
                },
            ],
        },
        {
            title: "Internships",
            experiences: [
                {
                    title: "Machine Learning Intern",
                    company: "TikTok Inc.",
                    company_url: "https://www.tiktok.com/en/",
                    logo_path: "tiktok_logo.png",
                    duration: "May 2022 - Aug 2022",
                    location: "San Francisco, USA",
                    description:
                        "Building new features on the backend recommendation system, specifically ranking algorithms for Ads that touch hundreds of millions of people around the world. Improving online and offline content ranking algorithms by performing hard sample data replays for training steps.",
                    color: "#000000",
                },
                {
                    title: "Data Science Research Intern",
                    company: "Delhivery Pvt. Ltd.",
                    company_url: "https://www.delhivery.com/",
                    logo_path: "delhivery_logo.png",
                    duration: "May 2019 - Sept 2019",
                    location: "Gurgaon, Haryana",
                    description:
                        "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
                    color: "#ee3c26",
                },
                {
                    title: "Data Science Intern",
                    company: "Intel Indexer LLC",
                    company_url:
                        "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
                    logo_path: "intel_logo.jpg",
                    duration: "Nov 2018 - Dec 2018",
                    location: "Work From Home",
                    description:
                        "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
                    color: "#0071C5",
                },
            ],
        },
        {
            title: "Volunteerships",
            experiences: [
                {
                    title: "Google Explore ML Facilitator",
                    company: "Google",
                    company_url: "https://about.google/",
                    logo_path: "google_logo.png",
                    duration: "June 2019 - April 2020",
                    location: "Hyderabad, Telangana",
                    description:
                        "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
                    color: "#4285F4",
                },
                {
                    title: "Microsoft Student Partner",
                    company: "Microsoft",
                    company_url: "https://www.microsoft.com/",
                    logo_path: "microsoft_logo.png",
                    duration: "Aug 2019 - May 2020",
                    location: "Hyderabad, Telangana",
                    description:
                        "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
                    color: "#D83B01",
                },
                {
                    title: "Mozilla Campus Captain",
                    company: "Mozilla",
                    company_url: "https://www.mozilla.org/",
                    logo_path: "mozilla_logo.png",
                    duration: "Oct 2019 - May 2020",
                    location: "Kurnool, Andhra Pradesh",
                    description:
                        "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
                    color: "#000000",
                },
                {
                    title: "Developer Students Club Member",
                    company: "DSC IIITDM Kurnool",
                    company_url:
                        "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
                    logo_path: "dsc_logo.png",
                    duration: "Jan 2018 - May 2020",
                    location: "Kurnool, Andhra Pradesh",
                    description:
                        "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
                    color: "#0C9D58",
                },
                {
                    title: "Developer Program Member",
                    company: "Github",
                    company_url: "https://github.com/",
                    logo_path: "github_logo.png",
                    duration: "July 2019 - PRESENT",
                    location: "Work From Home",
                    description:
                        "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
                    color: "#181717",
                },
            ],
        },
    ],
};

// Projects Page
const projectsHeader = {
    title: "Projects",
    description:
        "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
    avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
    title: "Publications",
    description: "Some of my published Articles, Blogs and Research.",
    avatar_image_path: "projects_image.svg",
};

const publications = {
    data: [
        {
            id: "neuro-symbolic-sudoku-solver",
            name: "Neuro-Symbolic Sudoku Solver",
            createdAt: "2023-07-02T00:00:00Z",
            description: "Paper published in KDD KiML 2023",
            url: "https://arxiv.org/abs/2307.00653",
        },
        {
            id: "mdp-diffusion",
            name: "MDP-Diffusion",
            createdAt: "2023-09-19T00:00:00Z",
            description: "Blog published in Paperspace",
            url: "https://blog.paperspace.com/mdp-diffusion/",
        },
        {
            id: "consistency-models",
            name: "Consistency Models",
            createdAt: "2023-10-12T00:00:00Z",
            description: "Blog published in Paperspace",
            url: "https://blog.paperspace.com/consistency-models/",
        },
    ],
};

// Contact Page
const contactPageData = {
    contactSection: {
        title: "Kontakt",
        profile_image_path: "mark_logo1.png",
        description:
            "Ich bin so gut wie jederzeit über die verschiedenen Kanäle erreichbar und unterstütze Sie gerne bei Ihrem nächsten Projekt.",
    },
    blogSection: {
        title: "Blogs",
        subtitle:
            "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
        link: "https://blogs.ashutoshhathidara.com/",
        avatar_image_path: "blogs_image.svg",
    },
    addressSection: {
        title: "Adresse",
        subtitle: "13595 Berlin, Deutschland",
        locality: "Berlin",
        country: "Deutschland",
        region: "Berlin",
        postalCode: "13595",
        streetAddress: "Genfenbergstraße",
        avatar_image_path: "address_image.svg",
        location_map_link: "https://maps.app.goo.gl/5k6WVzb5nUmW3gVX8",
    },
    phoneSection: {
        title: "",
        subtitle: "",
    },
};

export {
    settings,
    seo,
    greeting,
    socialMediaLinks,
    skills,
    competitiveSites,
    degrees,
    certifications,
    experience,
    projectsHeader,
    publicationsHeader,
    publications,
    contactPageData,
};
